<script>
export default {
  locales: {
    pt: {
      'My Investment': 'Meu Investimento',
      'My Profit': 'Meu Rendimento',
      'My Team': 'Minha Equipe',
      'Total Bonus': 'Total Bônus',
      'Current Balance': 'Saldo Atual',
      'Graduation': 'Graduação',
      'No Graduation': 'Sem Graduação',
      'Points': 'Pontos',
      'Awards Balance': 'Saldo de Premiação',
      'Donation Balance': 'Saldo de Doação',
    },
    es: {
      'My Investment': 'Mi Inversion',
      'My Profit': 'Mi Rendimiento',
      'My Team': 'Mi Equipo',
      'Total Bonus': 'Total Bonus',
      'Current Balance': 'Saldo Actual',
      'Graduation': 'Graduación',
      'No Graduation': 'No Graduación',
      'Points': 'Puntos',
      'Awards Balance': 'Saldo de Premiação',
      'Donation Balance': 'Saldo de Doação',
    }
  },
  props: {
    title: {
      type: String,
      default: ""
    },
    value: {
      type: String,
      default: ""
    },
    icon: {
      type: String,
      default: ""
    }
  }
};
</script>

<template>
  <div class="card mini-stats-wid">
    <div class="card-body">
      <div class="media">
        <div class="media-body row">
          <p class="col col-lg-12 text-muted font-weight-medium mb-0 mb-md-1">{{ t(title) }}</p>
          <h5 class="col col-lg-12 text-right-sm mb-0 notranslate">{{ t(value) }}</h5>
        </div>
        <div class="d-none d-sm-flex mini-stat-icon avatar-sm align-self-center rounded-circle bg-danger">
          <span class="avatar-title">
            <i :class="`${icon} font-size-24`"></i>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>