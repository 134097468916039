<script>
import Layout from '../../layouts/main';
import { api } from '@/state/services';
import { VclList } from 'vue-content-loading';
import Stat from '@/components/widgets/stat';

export default {
  locales: {
    en: {
      months: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    },
    pt: {
      'We apologize, we are unable to retrieve information at this time. Please try again later.': 'Pedimos desculpas, não podemos recuperar informações no momento. Por favor, tente novamente mais tarde.',
      'No records found.': 'Nenhum registro encontrado.',

      months: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
    },
    es: {
      'We apologize, we are unable to retrieve information at this time. Please try again later.': 'Nos disculpamos, no podemos recuperar información en este momento. Por favor, inténtelo de nuevo más tarde.',
      'No records found.': 'No se encontraron registros.',

      months: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
    }
  },
  components: { Layout, VclList, Stat },
  data() {
    return {
      tableBody: null,

      loading: true,
      errored: false,
      empty: false,

      statData: null,
    };
  },
  methods: {
    getList() {
      this.tableBody = null

      this.loading = true
      this.errored = false

      api
        .get('auction/awards')
        .then(response => {
          if (response.data.status == 'success') {
            this.tableBody = response.data.list
          }
        })
        .catch(error => {
          this.errored = error
        })
        .finally(() => {
          this.loading = false
        })
    }
  },
  mounted() {
    this.getList()
  },
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex justify-content-between">
          <h4 class="mb-0 font-size-18">{{ t('Meus Prêmios') }}</h4>
          <div class="page-title-right">
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div v-for="stat of statData" :key="stat.icon" class="col-md-4">
        <Stat :icon="stat.icon" :title="stat.title" :value="stat.value" />
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div v-if="errored">{{ t('We apologize, we are unable to retrieve information at this time. Please try again later.') }}</div>
            <div v-else-if="tableBody && tableBody.length == 0">{{ t('No records found.') }}</div>
            <div v-else class="table-responsive">
              <vcl-list v-if="loading" class="col-lg-6"></vcl-list>
              <table v-else class="table table-nowrap">
                <thead class="thead-light">
                  <tr>
                    <th>#</th>
                    <th>Produto</th>
                    <th>Lances</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(td,index) in tableBody" :key="index">
                    <td class="notranslate">REF. {{ td.id }}</td>
                    <td class="notranslate text-truncate">{{ td.name }}</td>
                    <td class="notranslate">{{ td.bids }}</td>
                    <td class="notranslate">
                      <span v-if="td.awarded == 'in_progress'">Leilão em Andamento</span>
                      <span v-else-if="td.awarded == 'win'">Você Ganhou!</span>
                      <span v-else-if="td.awarded == 'loss'">Você Não Ganhou!</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style scoped>
.text-truncate {
  display: block;
  overflow: hidden;
  white-space: nowrap;
  margin-right: 10px;
  text-overflow: ellipsis;
  width: calc(100vw * 0.3);
}
</style>
